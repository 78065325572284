<template>
	<div class="from">
		<div class="form-floating mb-3">
			<input type="last_name"
				   class="form-control rounded-1"
				   id="userLastName"
				   v-model="this.last_name"
				   placeholder="Ваша фамилия" autofocus />
			<label for="userLastName">Фамилия</label>
		</div>
		<div class="form-floating mb-3">
			<input type="first_name"
				   class="form-control rounded-1"
				   id="userFirstName"
				   v-model="this.first_name"
				   placeholder="Ваше имя" />
			<label for="userFirstName">Имя</label>
		</div>
		<div class="form-floating mb-3">
			<input type="middle_name"
				   class="form-control rounded-1"
				   id="userMiddleName"
				   v-model="this.middle_name"
				   placeholder="Ваше Отчество" />
			<label for="userMiddleName">Отчество</label>
		</div>
		Регион
		<div class="form-floating mb-3">
			<input type="phone"
				   class="form-control rounded-1"
				   id="userPhone"
				   v-model="this.phone"
				   v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
				   placeholder="В формате +7..." />
			<label for="userPhone">Телефон</label>
		</div>
		<div class="form-floating mb-3">
			<input type="email"
				   class="form-control rounded-1"
				   id="userEmail"
				   v-model="this.email"
				   v-maska="{ mask: 'a*@a*', tokens: { 'a': { pattern: /[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]/, uppercase: false }}}"
				   placeholder="Адрес эл. почты" />
			<label for="userEmail">Эл. почта</label>
		</div>
		<button class="btn w-100 btn-lg py-3 text-white btn-primary">Регистрация</button>
		<p class="mt-3">
			Регистрируясь Вы принимаете условия <a href="/agreement.php">пользовательского соглашения</a> и
			<a href="/privacy.php">политики конфиденциальности</a>.
		</p>
	</div>
	<div class="col border-dark border-top d-flex pt-3 pb-4 mt-4">
		<router-link to="/login" class="link link-dashed">Войти</router-link>
	</div>
</template>

<script>
import CommonService from "../../services/CommonService";

export default {
	name: "RegisterForm",
	data() {
		return {
			first_name: null,
			middle_name: null,
			last_name: null,
			region_id: null,
			phone: null,
			email: null,
		};
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
	},
	mounted() {
	}
};
</script>